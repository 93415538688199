import { HomePage } from '@components/Home/HomePage';
import { BaseLayout } from '@components/Layout';
import { t } from '@lingui/macro';
import { getGraphqlClient, GuidesFragment } from '@milo/react';
import { GetStaticProps } from 'next';

export default function Home(props: any) {
  return (
    <BaseLayout transparentNavigation colorScheme="light">
      <HomePage {...props} />
    </BaseLayout>
  );
}

export const getStaticProps: GetStaticProps = async () => {
  const client = getGraphqlClient();
  const popular = await client.request(`
    query GuidesPopular {
      guides: GuidesPopular {
        ${GuidesFragment}
      }
    }
  `);

  const experiences = await client.request(
    /** GraphQL */ `
    query ExperienceBatchGet($ids: [Int]) {
      results:     ExperienceBatchGet(ids:$ids) {
        id
        main_image
        slug
        savings_max
      }
    }`,
    {
      ids: [1836, 2380, 4462],
    }
  );

  return {
    props: {
      guides: popular.guides,
      experiences: experiences.results,
      seo: {
        title: t`M ta région`,
        description: t`Découvrez les meilleures expériences autour de vous!`,
      },
    },
  };
};
